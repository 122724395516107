.main {
    background-color: #fff;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    position: relative;
    max-width: 205px;
    height: 100%;

    @media screen and (min-width: 1024px) {
        transition: all 0.2s ease-in-out;
    }
    &:hover {
        @media screen and (min-width: 1024px) {
            box-shadow: 0px 5px 34px rgba(0, 0, 0, 0.14);
        }
    }
}

.active,
.desactive,
.waiting {
    position: absolute;
    top: 8px;
    left: 8px;
    height: 23px;
    padding: 0 8px;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    border-radius: 6px;
    z-index: 1;
    span {
        width: 5px;
        height: 5px;
        border-radius: 5px;
        margin-right: 4px;
    }
}

.active {
    color: #28910d;
    background: #ddffd5;
    span {
        background-color: #28910d;
    }
}

.desactive {
    background: #ffdedc;
    color: #b53e35;
    span {
        background-color: #b53e35;
    }
}

.waiting {
    background-color: #f5e0d1;
    color: #a9571b;
    span {
        background-color: #a9571b;
    }
}

.picture {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
    position: relative;
    border-radius: 8px 8px 0 0;
    background: var(--grey300);
    overflow: hidden;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    svg {
        width: 40px;
    }
}

.infos {
    padding: 16px;
}

.name {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: var(--grey900);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.styles {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 8px;
    .styleItem {
        position: relative;
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        height: 22px;
        background: var(--grey300);
        border-radius: 32px;
        font-weight: 600;
        font-size: 8px;
        line-height: 10px;
        color: var(--grey900);
        margin-right: 6px;
        padding: 0 10px;
        &:last-child {
            margin-right: 0;
            &:hover {
                .tooltip {
                    display: block;
                }
            }
        }
        .tooltip {
            position: absolute;
            top: 100%;
            background: var(--grey900);
            border-radius: 8px;
            padding: 8px;
            font-weight: 600;
            font-size: 8px;
            line-height: 12px;
            text-align: center;
            color: var(--grey300);
            width: 150px;
            left: 50%;
            transform: translate(-50%, 4px);
            z-index: 2;
            display: none;
            span {
                margin-right: 8px;
                &:after {
                    content: ",";
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}
.delete {
    position: absolute;
    top: 13px;
    right: 13px;
    background: rgba(26, 32, 44, 0.6);
    border-radius: 6px;
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    z-index: 1;
    svg {
        width: 18px;
    }
    &:hover {
        background: rgba(26, 32, 44, 0.8);
    }
}

.popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    .box {
        position: relative;
        width: 515px;
        padding: 40px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 12px 36px rgba(0, 0, 0, 0.08);
    }
}

.popupTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--grey900);
    padding-bottom: 24px;
    border-bottom: 2px solid var(--grey300);
    margin-bottom: 40px;
}

.popupText {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 24px;
    color: var(--grey700);
}

.popupWarning {
    padding: 16px;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: #b45c1c;
    border-radius: 6px;
    background-color: #ffeee2;
    margin-bottom: 32px;
}

.popupButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
}

.email {
    font-size: 11px;
    line-height: 14px;
    color: var(--grey700);
    margin-top: 4px;
}

.createdAt {
    margin-top: 20px;
    font-size: 10px;
    font-weight: 400;
    line-height: 11px;
    color: var(--grey600);
}
