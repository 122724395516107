.m-select {
  cursor: pointer;
  position: relative;
  width: 100%;
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: var(--grey600);
  background-color: #fff;
  border: 1px solid var(--grey400);
  padding: 12px 38px 12px 16px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  color: var(--grey900);
  &:disabled {
    background-color: var(--grey200);
    cursor: not-allowed;
  }
  &--red {
    border-color: var(--dark-red)
  }
  &--grey {
    border:none;
    background: var(--grey300);
    &:hover {
      border:none;
      background: var(--grey400);
    }
  }
  &:hover {
    border-color: var(--grey500)
  }
}


.m-select-box {
  cursor: pointer;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0em;
  color: var(--grey600);
  background-color: #fff;
  border: 1px solid var(--grey400);
  height: 39px;
  border-radius: 8px;

  &:hover {
    border-color: var(--grey500)
  }

  color: var(--grey900);
  &:disabled {
    background-color: var(--grey200);
    cursor: not-allowed;
  }
  &--red {
    border-color: var(--dark-red)
  }
  &--small {
    max-width: 109px;
  }
  select {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    padding: 0 16px;
    font-size: 12px;
    line-height: 15px;
  }
  svg {
    width: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}