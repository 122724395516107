.subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 48px;
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--grey900);
    .subtitle_icon {
        width: 20px;
    }
}

.grid {
    display: grid;
    column-gap: 32px;
    row-gap: 24px;
    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
}

.subCategory {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--grey400);
    padding: 11px 11px 11px 16px;
    border-radius: 8px;
    label {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        color: var(--grey900);
    }
}

.subCategoryLabel {
   
    display: flex;
    align-items: center;
    gap: 12px;
    svg {
      width: 14px;
    }
    .stroke {
        svg {
            stroke: var(--purple);
        } 
    }
}

.checkboxsItem {

    display: flex;
    align-items: center;
    input {
        position: absolute;
        cursor: pointer;
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
        left: 0;
        &:hover  ~ .checkmark{
            border: 1px solid var(--grey500);
        }
        &:checked ~ .checkmark {
            background-color: var(--green);
            border: none;
            .check_icon {
              display: block;

            }
          }
    }
    .checkmark {
        width: 17px;
        height: 17px;
        border-radius: 5px;
        flex-shrink: 0;
        border: 1px solid var(--grey400);
    }
    .checkLabel {
        margin-left: 8px;
        font-weight: 700;
        font-size: 10px;
        line-height: 13px;
        color: var(--grey700);
    }
    .check_icon {
        display: none;
        width: 17px;
        position: absolute;
        pointer-events: none;
    }          
}