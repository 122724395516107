.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 2px solid var(--grey300);
    margin-bottom: 48px;
}

.title {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    color: var(--blue);
    
}

.item {
    background: #FFFFFF;
    margin-bottom: 24px;
    padding: 24px;
    border: 1px solid var(--grey400);
    border-radius: 8px;
    &:last-child {
        margin-bottom: 0;
    }
}

.name {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: var(--grey900);
}

.text {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--grey700);

}

.number {
    margin-top: 16px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--grey900);
    svg {
        width: 12px;
        margin-right: 10px
    }
}

.hours {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
}

.hourItem {
    padding: 8px 12px;
    background: var(--grey300);
    border-radius: 6px;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    color: var(--grey900);
    margin-right: 8px;
    margin-bottom: 8px;
    &:last-child {
        margin-right: 0;
    }
}