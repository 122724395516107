.main {
    @media screen and (min-width: 768px) {
        display: flex;
    }
    .bar {
        width: 100%;
       
        flex-shrink: 0;
        @media screen and (min-width: 768px) {
            max-width: 212px;
        }
    }
    .transport {
        background: #FFFFFF;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14);
        border-radius: 8px;
        margin-left: 24px;
        margin-top: 24px;
        padding: 24px;
        width: 100%;
        @media screen and (min-width: 768px) {
            margin-top: 0;
            padding: 24px 32px;
        }
        .label {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: var(--grey900);
        }
        .text {
            margin-top: 16px;
            font-weight: 400;
            font-size: 13px;
            line-height: 24px;
            color: var(--grey900);

        }
    }
}