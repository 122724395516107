.subtitle {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    gap: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--grey900);
    svg {
        width: 20px; 
    }
}