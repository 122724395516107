.m-input {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0em;
  color: var(--grey900);
  background-color: #fff;
  border: 1px solid var(--grey400);
  padding: 0 16px;
  border-radius: 8px;
  text-align: left;
  height: 39px;
  max-width: 100%;
  &:disabled {
    background-color: var(--grey200);
    cursor: not-allowed;
  
  }
  &--small {
    max-width: 109px;
  }
  &--small115 {
    max-width: 115px;
  }
  &--textarea {
    height: 115px;
    padding: 8px;
   
  }
  &--grey {
    border:none;
    background: var(--grey300);
    &:hover {
      border:none;
      background: var(--grey400);
    }
    
  }
  &::placeholder {
    color: var(--grey600);
  }
  &:hover {
    border-color: var(--grey500)
  }
  &:focus {
    border-color: var(--grey500)!important;
  }
  &:focus-visible {
    border-color: var(--grey500)!important;
  }
  &--red {
    border: 1px solid var(--dark-red);
  }
}
