.main {
    padding: 49px 0 80px 0;
}

.back_link {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    color: var(--grey600);
    width: auto;
    margin-bottom: 16px;
    &:hover {
        color: var(--grey700);
        svg {
            stroke: var(--grey700)
        }
    }
   
    svg {
        width: 15px;
        margin-right: 8px;
        stroke: var(--grey600)
    }
}



.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 40px;
    flex-wrap: wrap;
}

.headerButtons {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
}

.title {
    font-size: 38px;
    font-weight: 800;
    line-height: 48px;
    color: var(--grey900);
    max-width: calc(100% - 150px);
}

.linkout_icon {
    width: 15px;
}
  
.eye_icon {
    width: 15px;
}

.pen_icon {
    width: 15px;
}
  

.subheader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 2px solid var(--grey300);
    margin-bottom: 48px;
}

.subtitle {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    color: var(--blue);
}

.section {
    background-color: #fff;
    padding: 24px 12px 24px 12px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14);
    &:not(:last-child) {
      margin-bottom: 48px;
    }
    @media screen and (min-width: 500px) {
      padding: 40px 56px 48px 56px;
    }
}
