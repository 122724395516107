@import "../tools/functions";

:root {
  --grey0: #fff;
  --grey100: #f7fafc;
  --grey200: #edf2f7;
  --grey300: #e2e8f0;
  --grey400: #cbd5e0;
  --grey500: #a0aec0;
  --grey600: #718096;
  --grey700: #4a5568;
  --grey800: #2d3748;
  --grey900: #1a202c;
  --green: #12ed94;
  --greenhover: #06DE86;
  --blue: #2d448a;
  --bluelight: #3974EB;
  --bluedark: #2255BB;
  --yellow: #ecc55d;
  --red: #d96e66;
  --dark-red: #ca2929;
  --dark-red-hover: #AC1A1A;
  --orange: #e08746;
  --purple: #352545;
  --pink: #f1e2ff;
  --beige: #f6f6f3;
  --greendark: #079F62;
}

