.account_logo {
  position: absolute;
}

.logo {
  @media screen and (min-width: 768px) {
    margin-bottom: 32px;
  }
}

.menu_button {
  @media screen and (min-width: 768px) {
    display: none !important;
  }
}

.link {
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: var(--grey600);
  &:hover {
    @media screen and (min-width: 1024px) {
      color: var(--grey800);
      svg {
        stroke: var(--grey800)
      }
    }
  }
  &__active {
    color: #079f62;
  }
  svg {
    width: 16px;
    margin-right: 8px;
    stroke: var(--grey600);
   
  }
}


