.main {
    position: relative;
    height: 110px;
    a {
        border-radius: 8px;
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--grey200);
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 32px;
        }
    }
    img {
        border-radius: 8px;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.name {
    margin-top: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--grey700);
}

.delete {
    position: absolute;
    top: 13px;
    right: 13px;
    background: rgba(26, 32, 44, 0.6);
    border-radius: 6px;
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    z-index: 1;
    &:hover {
        background: rgba(26, 32, 44, 0.8);
    }
    svg {
        width: 18px;
    }
}

.popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    .box {
        position: relative;
        width: 515px;
        padding: 40px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 12px 36px rgba(0, 0, 0, 0.08);
    }
}

.popupTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--grey900);
    padding-bottom: 24px;
    border-bottom: 2px solid var(--grey300);
    margin-bottom: 40px;
}

.popupText {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: var(--grey700);
}

.popupButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
}