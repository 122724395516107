.main {
    display: flex;
    align-items: center;
    &:hover {
        @media screen and (min-width: 1024px) {
            p {
                color: var(--grey700);
            }
            .download_icon {
                stroke: var(--grey700);
            }
        }
    }
    p {  
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        color: var(--grey600);
        
    }
}


.download_icon {
    width: 15px;
    min-width: 15px;
    margin-right: 8px;
    stroke: var(--grey600);
}