.subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 48px;
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--grey900);
    .subtitle_icon {
        width: 20px;
    }
}

textarea {
    display: block;
    width: 100%;
    height: 115px;
    padding: 12px 16px;
    border: 1px solid var(--grey400);
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: var(--grey900);
    border-radius: 8px;
    resize: none;
    background-color: #fff;
    max-width: 100%;
    &.red {
        border-color: var(--dark-red)
    }
}
