.box {
    display: flex;
    align-items: center;
    width: 100%;
}

.city {
    position: relative;
    width: 100%;
}

.cities {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    li {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
        button {
            text-align: left;
        }
    }
}

.rayon {
    max-width: 74px;
    margin-left: 16px;
    input {
        padding: 0;
        text-align: center;
    }
}

.delete {
    margin-top: 8px;
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
    color: var(--grey700);
    &:hover {
        color: var(--grey800);
    }
}
