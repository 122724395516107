%all {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  @extend %all;
}

html {
  margin: 0;
  padding: 0;
} 

body {
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  background-color: var(--grey100);

  &.overflow {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
}

img {
  box-sizing: border-box;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

textarea,
input,
button,
select {
  -webkit-appearance: none;
  background: none;
  border: none;
  outline: none;
  &:focus {
    outline: none;
  }
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
}

button {
  cursor: pointer;
}

