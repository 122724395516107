.list {
    margin: 0 -24px;
    @media screen and (min-width: 500px) {
        display: flex;
    }
    .item {
        width: 100%;
        padding: 0 24px;
        margin-bottom: 32px;
    }
}


.checkboxs {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.checkboxsSingle {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 32px;
    @media screen and (min-width: 500px) {
        margin-top: 0;
        margin-left: 48px;
    }
    &:first-child {
        margin-top: 0;
        margin-left: 0;
    }
    input {
        position: absolute;
        cursor: pointer;
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
        &:hover  ~ .checkmark{
            border: 1px solid var(--grey500);
        }
        &:checked ~ .checkmark {
            background-color: var(--green);
            border: none;
            .check_icon {
              display: block;

            }
          }
    }
    .checkmark {
        width: 17px;
        height: 17px;
        border-radius: 5px;
        flex-shrink: 0;
        border: 1px solid var(--grey400);
    }
    .checkLabel {
        margin-left: 8px;
        font-weight: 700;
        font-size: 10px;
        line-height: 13px;
        color: var(--grey700);
    }
    .check_icon {
        display: none;
        width: 17px;
        position: absolute;
        pointer-events: none;
    }
    p {
        margin-left: 8px;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
    }    
}