.logo {
  position: absolute;
  left: 24px;
  top: 48px;
  @media screen and (min-width: 768px) {
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
  @media screen and (min-width: 1024px) {
    width: 100%;
    left: 50%;
    max-width: 384px;
    transform: translateX(-50%);
  }
}

.link {
  display: block;
  width: fit-content;
  margin: 32px auto 0 auto;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
  color: var(--bluelight);
  &:hover {
    color: var(--bluedark);
  }
}

.input {
  margin-bottom: 32px;
}

.eye_icon {
  width: 15px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
  &:hover {
    svg {
      stroke: var(--grey700);
    }
  }
  svg { 
    width: 15px;
    stroke: var(--grey600);
  }
}
