.main {
  padding: 80px 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 2px solid var(--grey300);
  margin-bottom: 24px;
}

.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  color: var(--grey900);
}

.export {
  display: flex;
  align-items: center;
  p {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    color: var(--grey600);
  }
}

.download_icon {
  width: 15px;
  min-width: 15px;
  margin-right: 8px;
}

.filters_wrapper {
  margin-bottom: 24px;
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  button {
    white-space: nowrap;
  }
}

.search_wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  @media screen and (min-width: 768px) {
    justify-content: flex-end;
    margin-bottom: 0;
  }
}

.search_input_wrapper {
  position: relative;
  width: 100%;
  @media screen and (min-width: 375px) {
    width: 209px;
  }
  .searchLoading {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    .m-loading {
      background-color: var(--green);
    }
  }
  svg {
    width: 15px;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}

.search__icon {
  width: 15px;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.add {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  padding: 12px 16px;
  background-color: var(--green);
  border-radius: 8px;
  &:hover {
    background-color: var(--greenhover);
  }
  p {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    color: var(--purple);
  }
}

.add__icon {
  width: 15px;
}

.table_head {
  border-radius: 8px 8px 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1.8fr 1fr 0.7fr;
  background-color: var(--grey200);
  padding: 16px 0 16px 24px;
  p {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    color: #000000;
  }
}

.table_body {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14);
  border-radius: 0 0 8px 8px;
  a {
    position: relative;
    display: block;
    &:not(:last-child) {
      border-bottom: 1px solid var(--grey300);
    }
  }
}

.table_item {
  border-radius: 0 0 8px 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1.8fr 1fr 0.7fr;
  background-color: #fff;
  padding: 16px 0 16px 24px;
  &:hover {
    background-color: var(--grey100);
  }
}

.table_text {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: #000000;
}

.user_state {
  width: fit-content;
}

.table_arrow {
  position: absolute;
  width: 16px;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
}

.loading {
  width: 56px;
  height: 56px;
  background-color: var(--green);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: fixed;
  right: 32px;
  z-index: 20;
  bottom: 32px;
}

.sort {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .filterButton {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 39px;
    background: var(--grey300);
    border-radius: 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: var(--grey900);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      @media screen and (min-width: 1024px) {
        background: var(--grey400);
      }
    }
    .number {
      margin-left: 8px;
      padding: 2px 4px;
      background: var(--green);
      border-radius: 4px;
      font-weight: 700;
      font-size: 9px;
      line-height: 11px;
      color: var(--grey900);
    }
    svg {
      margin-left: 8px;
      width: 15px;
    }
  }
  ul {
    width: 205px;
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 12px 36px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 16px 24px;
    z-index: 2;
    top: calc(100% + 8px);
    li {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      button {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: var(--grey900);
        &:hover {
          color: var(--greendark);
          svg {
            stroke: var(--greendark);
          }
        }
        &.active {
          color: var(--greendark);
          svg {
            stroke: var(--greendark);
          }
        }
        svg {
          width: 15px;
          margin-right: 8px;
          line-height: 0;
          stroke: var(--grey600);
        }
      }
    }
  }
}

.more {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  margin-top: 16px;
}

.dateFilterButton {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 39px;
  background: var(--grey300);
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: var(--grey900);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (min-width: 320px) {
    margin-left: 8px;
  }
  &:hover {
    @media screen and (min-width: 1024px) {
      background: var(--grey400);
    }
  }
  svg {
    stroke: var(--grey600);
    margin-right: 8px;
    width: 15px;
  }
}

.dateFilter {
  position: relative;
  margin-top: 16px;
  @media screen and (min-width: 320px) {
    margin-top: 0;
  }
}

.resetDateFilter {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  display: flex;
  svg {
    fill: var(--grey600);
    width: 15px;
    height: 15px;
  }
}

.dateFilterBox {
  display: flex;
  top: calc(100% + 8px);
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 12px 36px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  z-index: 3;
  width: 100vw;
  left: -18px;
  @media screen and (min-width: 305px) {
    left: -118px;
  }
  @media screen and (min-width: 768px) {
    width: 800px;
    left: -318px;
  }
  @media screen and (min-width: 1250px) {
    left: 0;
  }
}
