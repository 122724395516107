.relative {
    position: relative;
}

.search {
    border: 1px solid var(--grey300);
    position: relative;
    border-radius: 8px;
    input {
        height: 39px;
        width: 100%;
        padding: 0 16px;
    }
    svg {
      width: 15px;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
    }
    .searchLoading {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      .m-loading {
        background-color: var(--green);
      }
    }
  }
.popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    .box {
        position: relative;
        width: 515px;
        padding: 40px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 12px 36px rgba(0, 0, 0, 0.08);
    }
}

.popupTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--grey900);
    padding-bottom: 24px;
    border-bottom: 2px solid var(--grey300);
    margin-bottom: 40px;
}

.otherpopup {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    margin-top: 16px;
    color: var(--bluelight);
}

.popupButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
}

.musicians {
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 14%);
    border-radius: 8px;
    position: absolute;
    width: 100%;
    top: calc(100% + 2px);
    max-height: 200px;
    overflow: auto;
    .picture {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        position: relative;
        width: 40px;
        height: 40px;
        background: var(--grey200);
        border-radius: 6px;
        margin-right: 12px;
        overflow: hidden;
        img {
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        svg {
            width: 20px;
        }
    }
    .name {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: var(--grey900);
        text-align: left;

    }
    .location {
        margin-top: 2px;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        color: var(--grey700);
        text-align: left;
    }
    .style {
        margin-top: 2px;
        font-size: 10px;
        line-height: 13px;
        color: var(--grey700);
        text-align: left;
    }
}

.musicianButton {
    display: flex;
    padding: 8px;
    width: 100%;
    align-items: center;
    &:hover {
        background-color: var(--grey300);
    }
}