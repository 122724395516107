.head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 2px solid var(--grey300);
    margin-bottom: 48px;
}

.title {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    color: var(--blue);
}

.subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 48px;
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--grey900);
    .subtitle_icon {
        width: 20px;
    }
}

.form {
    display: grid;
    column-gap: 32px;
    row-gap: 32px;
    @media screen and (min-width: 1200px) {
        grid-template-columns: 1fr 1fr;
    }
}

.city {
    position: relative;
}

.cities {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    z-index: 2;
    li {
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0;
        }
        button {
            text-align: left;
        }
    }
}

.flex {
    display: flex;
    align-items: center;
    gap: 32px;
}
