.container {
    padding: 24px;
    background-color: #fff;
    border-radius: 16px;
    width: 100%;
    & > div > div:first-of-type {
        @media screen and (min-width: 800px) {
            display: flex;
            justify-content: space-between;
        }
    }
    & > div > div > div:nth-child(2) > div > div {
        & > button:last-child {
            display: none;
        }
        & p {
            margin: 0 auto;
        }
    }
    & > div > div > div:nth-child(3) > div > div {
        & > button:first-child {
            display: none;
        }
        & p {
            margin: 0 auto;
        }
    }
}

.datepicker,
.datepickerActive {
    text-align: center;
    margin: 16px auto 0;
    max-width: 364px;
    @media screen and (min-width: 1024px) {
        text-align: center;
        margin: 0 auto;
        max-width: inherit;
        padding: 24px 0;
        border-top: 1px solid var(--grey300);
    }
}

.datepicker {
    display: none;
}

.datepickerActive {
    display: block;
}
.calendarHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 32px;
    p {
        font-size: 13px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;
        color: var(--grey900);
    }
}

.calendarText {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: var(--grey600);
    margin-bottom: 24px;
    @media screen and (min-width: 1024px) {
        margin-bottom: 8px;
    }
}

.decreaseMonth,
.increaseMonth {
    padding: 8px;
    background-color: var(--grey200);
    border-radius: 5px;
    display: flex;
    svg {
        font-size: 16px;
        color: var(--grey700);
    }
}

.buttons {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
