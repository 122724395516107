.main {
  margin-bottom: 32px;
  @media screen and (min-width: 425px) {
    display: grid;
    grid-template-columns: 3fr 1fr;
    column-gap: 32px;
  }
}

.generate_title {
  font-size: 10px;
  font-weight: 700;
  line-height: 13px;
  color: var(--grey700);
  margin-top: 8px;
}
