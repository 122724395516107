.head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 2px solid var(--grey300);
    margin-bottom: 48px;
}

.title {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    color: var(--blue);
    span {
        color: var(--red);
    }
}

.grid {
    display: grid;
    column-gap: 32px;
    row-gap: 24px;
    margin-top: 16px;
    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
}

.subCategory {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--grey400);
    padding: 11px 11px 11px 16px;
    border-radius: 8px;
    &:hover {
        border-color: var(--grey500);
    }
    label {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        color: var(--grey900);
    }
}

.subCategoryLabel {
    display: flex;
    align-items: center;
    gap: 12px;
    svg {
        width: 14px;
    }
}

.checkboxsSingle {
    position: relative;
    margin-top: 32px;
    &:first-child {
        margin-top: 0;
    }
    p {
        margin-left: 8px;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;

        color: #000000;
    }
}

.checkboxsItem,
.checkboxsSingle {
    display: flex;
    align-items: center;
    input {
        position: absolute;
        cursor: pointer;
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
        left: 0;
        &:hover ~ .checkmark {
            border: 1px solid var(--grey500);
        }
        &:checked ~ .checkmark {
            background-color: var(--green);
            border: none;
            .check_icon {
                display: block;
            }
        }
    }
    .checkmark {
        width: 17px;
        height: 17px;
        border-radius: 5px;
        flex-shrink: 0;
        border: 1px solid var(--grey400);
    }
    .checkLabel {
        margin-left: 8px;
        font-weight: 700;
        font-size: 10px;
        line-height: 13px;
        color: var(--grey700);
    }
    .check_icon {
        display: none;
        width: 17px;
        position: absolute;
        pointer-events: none;
    }
}
