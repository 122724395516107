.main {
  padding: 49px 0 80px 0;
}

.back_link {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  color: var(--grey600);
  width: auto;
  margin-bottom: 16px;
  &:hover {
    color: var(--grey700);
    svg {
      stroke: var(--grey700);
    }
  }

  svg {
    width: 15px;
    margin-right: 8px;
    stroke: var(--grey600);
  }
}
.title {
  font-size: 38px;
  font-weight: 800;
  line-height: 48px;
  color: var(--grey900);
  margin-bottom: 40px;
}

.form_container {
  display: grid;
  column-gap: 32px;
  row-gap: 32px;
  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
}

.select_wrapper {
  position: relative;
}

.arrow_down {
  width: 15px;
  position: absolute;
  right: 0;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.create_button {
  margin-top: 48px;
  margin-left: auto;
}

.bottom {
  margin-top: 64px;
}

.bottomBlock {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}

.bottomTitle {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: var(--blue);
  border-bottom: 2px solid var(--grey300);
  padding-bottom: 24px;
  margin-bottom: 40px;
}

.generate_password {
  font-size: 10px;
  font-weight: 700;
  line-height: 13px;
  color: var(--grey700);
  margin-top: 8px;
}

.password {
  ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 4px;
  }
  li {
    margin: 0;
    svg {
      width: 15px;
    }
    span {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
    }
    &.invalid span {
      color: var(--zzred);
    }
  }
}
