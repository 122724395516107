.item {
    margin-bottom: 24px;
    background: #FFFFFF;
    border: 1px solid var(--grey400);
    border-radius: 8px;
    padding: 24px;
    @media screen and (min-width: 768px) {
       display: flex;
       align-items: center;
       justify-content: space-between;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.role {
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    color: var(--purple);
    background: var(--grey300);
    border-radius: 6px;
    padding: 6px 8px;
    &--admin {
        background: #F1E2FF;
      
    }
    svg {
        margin-right: 5px;
        width: 12px;
    }
}

.name {
    margin-top: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: var(--grey900)
}

.date,
.email,
.phone {
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--grey700)
}

.link {
    margin-top: 24px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    color: var(--bluelight);
    @media screen and (min-width: 768px) {
        margin-top: 0;
        margin-left: 16px;
        flex-shrink: 0;
    }
    &:hover {
        color: var(--bluedark)
    }
}