.main {
    background: #FFFFFF;
    border: 1px solid var(--grey400);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
    @media screen and (min-width: 768px) {
        padding: 32px;
    }
}

.top {
    width: 100%;
    margin: 0 -16px;
    @media screen and (min-width: 768px) {
        display: flex;
    }
    .name,
    .number {
        width: 100%;
        padding: 0 16px;
    }
    .number {
        margin-top: 24px;
        max-width: 152px;
        @media screen and (min-width: 768px) {
            margin-top: 0;
        }
    }
  
}
.description,
.prices {
    margin-top: 32px;
}

.pricesLabel {
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--grey900);
}

.pricesList {
    @media screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -24px;
    }
    @media screen and (min-width: 1024px) {
        margin: 0 -33px;
    }
}

.pricesItem {
    margin-bottom: 24px;
    @media screen and (min-width: 768px) {
       padding: 0 24px;
       width: 50%;
    }
    @media screen and (min-width: 1024px) {
        padding: 0 33px;
        width: calc(100% / 3);
    }
}

.add {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: var(--bluelight);
    &:hover {
        color: var(--bluedark);
    }
}

.delete {
    margin-top: 32px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: var(--grey600);
    &:hover {
        color: var(--grey700);
    }
}