.main {
    display: inline-flex;
    align-items: center;
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 10;
    padding: 12px 16px;
    background: var(--grey900);
    border-radius: 40px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: var(--grey200);
    animation-name: toastAppear;
    animation-duration: 0.3s;
    svg {
        width: 16px;
        margin-right: 8px;
    }
}

@keyframes toastAppear {
    from {right: -16px;opacity: 0;}
    to {right: 16px;opacity: 1;}
}