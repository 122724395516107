.subtitle_icon {
    width: 20px;
}
  

.subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 48px;
    margin-bottom: 32px;
    p {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: var(--grey900);
    }
}


.text {
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    color: var(--grey900);
}