.main {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
    li {
      width: 100%;
      height: 110px;
      margin-bottom: 24px;
      padding: 0 12px;
      @media screen and (min-width: 500px) {
        width: 50%;
      }
      @media screen and (min-width: 1024px) {
        width: 25%;
      }
    }
  }
}

.head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 2px solid var(--grey300);
  margin-bottom: 48px;
}

.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  color: var(--blue);
}

.subtitle {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 48px;
  margin-bottom: 32px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: var(--grey900);
  .subtitle_icon {
    width: 20px;
  }
  &::after {
    content: "*";
    color: var(--red);
    margin-left: 2px;
  }
}

.add {
  position: relative;
  width: 100%;
  height: 110px;
  background-color: var(--grey200);
  border-radius: 8px;
  border: 1px solid var(--grey400);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  &:disabled {
    opacity: 0.3;
  }
  &:hover {
    @media screen and (min-width: 1024px) {
      background: var(--grey200);
      border: 1px solid var(--grey500);
      p {
        color: var(--grey700);
      }
    }
  }
  p {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    color: var(--grey600);
  }
  svg {
    width: 15px;
  }
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.formats {
  font-size: 11px;
  line-height: 14px;
  color: var(--grey600);
  b {
    color: var(--grey800);
    font-weight: 600;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey200);
  border-radius: 8px;
  border: 1px solid var(--grey400);
  height: 110px;
}

.errors {
  font-size: 11px;
  line-height: 14px;
  color: var(--dark-red);
}
