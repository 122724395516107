.main {
    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px;
        li {
            
            width: 100%;
            height: 110px;
            margin-bottom: 24px;
            padding: 0 12px;
            @media screen and (min-width: 500px) {
                width: 50%;
            }
            @media screen and (min-width: 1024px) {
                width: 25%;
            }
           
        }
    }
    
}

.subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 48px;
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--grey900);
    .subtitle_icon {
        width: 20px;
    }
}

.add {
    position: relative;
    width: 100%;
    height: 110px;
    background-color: var(--grey200);
    border-radius: 8px;
    border: 1px solid var(--grey400);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    &:hover {
        @media screen and (min-width: 1024px) {
            background: var(--grey200);
            border: 1px solid var(--grey500);
            p {
                color: var(--grey700);
            }
          
        }
    }
    p {
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        color: var(--grey600);
    }
    svg {
        width: 15px;
    }
}


.popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    .box {
        position: relative;
        width: 515px;
        padding: 40px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 12px 36px rgba(0, 0, 0, 0.08);
    }
}

.popupTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--grey900);
    padding-bottom: 24px;
    border-bottom: 2px solid var(--grey300);
    margin-bottom: 40px;
}

.popupText {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: var(--grey700);
}

.popupButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
}
