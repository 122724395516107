.main {
    display: flex;
}

.price,
.duration {
    width: 100%;
}

.price {
    max-width: 79px;
    margin-left: 16px;
}

.delete {
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
    color: var(--grey700);
}