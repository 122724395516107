.list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
    .item {
        width: 100%;
        margin-bottom: 24px;
        padding: 0 12px;
        @media screen and (min-width: 768px) {
            width: 33%;
        }
        @media screen and (min-width: 1024px) {
            width: 25%;
        }
    }
}