.subtitle_icon {
    width: 20px;
}
  

.subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 48px;
    margin-bottom: 32px;
    p {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: var(--grey900);
    }
}

.main {
    li {
       width: 100%;
       margin-bottom: 16px;
       &:last-child {
        margin-bottom: 0;
       }
       iframe {
        width: 100%;
        height: 84px;
       }
    }
}
