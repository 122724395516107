.logo {
  position: absolute;
  left: 24px;
  top: 48px;
  @media screen and (min-width: 768px) {
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
  @media screen and (min-width: 1024px) {
    width: 100%;
    left: 50%;
    max-width: 384px;
    transform: translateX(-50%);
  }
}

.back {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  color: var(--grey600);
  width: auto;
  margin-bottom: 16px;
  &:hover {
      color: var(--grey700);
      svg {
          stroke: var(--grey700)
      }
  }
 
  svg {
      width: 15px;
      margin-right: 8px;
      stroke: var(--grey600)
  }
}

.submit {
  margin-top: 16px;
}