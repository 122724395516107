.main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: var(--grey900);
}

.arrow,
.minus {
    height: 16px;
}
  
.arrow {
    margin: 0 10.5px;
}
.minus {
    margin: 0 16px;
}