.main {
    padding: 49px 0 80px 0;
}

.head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 2px solid var(--grey300);
    margin-bottom: 48px;
}

.title {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    color: var(--blue);
}



.back_link {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    color: var(--grey600);
    width: auto;
    margin-bottom: 16px;
    &:hover {
        color: var(--grey700);
        svg {
            stroke: var(--grey700)
        }
    }
   
    svg {
        width: 15px;
        margin-right: 8px;
        stroke: var(--grey600)
    }
}



.postcode_input {
max-width: 109px;
}

.linkout_icon {
width: 15px;
}

.eye_icon {
width: 15px;
}

.pen_icon {
width: 15px;
}

.subtitle_icon {
width: 20px;
}

.tag_icon {
width: 14px;
}

.title_link {
font-size: 13px;
font-weight: 700;
line-height: 16px;
color: #3974eb;
}

.section {
    background-color: #fff;
    padding: 24px 12px 24px 12px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14);
    &:not(:last-child) {
      margin-bottom: 48px;
    }
    @media screen and (min-width: 500px) {
      padding: 40px 56px 48px 56px;
    }
}

.description {
    margin-top: 24px
}

.location {
    margin-top: 48px
}



.bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}