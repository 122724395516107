.section {
  background-color: #fff;
  padding: 24px 12px 24px 12px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14);
  @media screen and (min-width: 500px) {
    padding: 40px 56px 48px 56px;
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 2px solid var(--grey300);
  margin-bottom: 48px;
}

.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  color: var(--blue);
}
