.main {
  padding-top: 80px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 2px solid var(--grey300);
  margin-bottom: 24px;
}

.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  color: var(--grey900);
}
.search {
  border: none !important;
  background-color: var(--grey300) !important;
  @media screen and (min-width: 365px) {
    width: 209px !important;
  }
  &__icon {
    width: 15px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  .searchLoading {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    .m-loading {
      background-color: var(--green);
    }
  }
}

.add__icon {
  width: 15px;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 80px;
  @media screen and (min-width: 500px) {
    padding-top: 188px;
  }
  svg {
    width: 45px;
    fill: var(--grey500);
  }
  p {
    margin-top: 20px;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    color: var(--grey700);
  }
}

.section {
  background-color: #fff;
  padding: 24px 12px 24px 12px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14);
  &:not(:last-child) {
    margin-bottom: 48px;
  }
  @media screen and (min-width: 500px) {
    padding: 40px 56px 48px 56px;
  }
}

.list {
  position: relative;
  display: flex;
  margin: 24px -12px 0;
  flex-wrap: wrap;

  .item {
    padding: 0 12px;
    margin-bottom: 24px;
    width: 50%;

    @media screen and (min-width: 550px) {
      width: calc(100% / 3);
    }
    @media screen and (min-width: 1024px) {
      width: calc(100% / 4);
    }
  }
}

.head {
  @media screen and (min-width: 1024px) {
    display: flex;
  }
}

.filterLeft {
  display: flex;
  flex-wrap: wrap;
}
.filter,
.sort {
  position: relative;

  .filterButton {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 39px;
    background: var(--grey300);
    border-radius: 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: var(--grey900);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      @media screen and (min-width: 1024px) {
        background: var(--grey400);
      }
    }
    .number {
      margin-left: 8px;
      padding: 2px 4px;
      background: var(--green);
      border-radius: 4px;
      font-weight: 700;
      font-size: 9px;
      line-height: 11px;
      color: var(--grey900);
    }
    svg {
      margin-left: 8px;
      width: 15px;
    }
  }
}

.filterBox {
  display: flex;
  top: calc(100% + 8px);
  max-width: 393px;
  width: auto;
  max-height: 296px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 12px 36px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  z-index: 3;
  ul {
    li {
      display: block;
      margin-bottom: 16px;
      button {
        display: flex;
        align-items: center;
        width: 100%;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #1a202c;
        &:hover {
          color: var(--greendark);
          .filter_icon {
            stroke: var(--greendark);
          }
        }
        &.active {
          color: var(--greendark);
          .filter_icon {
            width: 15px;
            stroke: var(--greendark);
          }
        }
        .filter_icon {
          width: 15px;
          margin-right: 8px;
          line-height: 0;
          stroke: var(--grey600);
        }

        .arrow_right {
          width: 15px;
          margin-left: auto;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.filterFirst {
  width: 176px;
  flex-shrink: 0;
  padding: 24px 16px 24px 16px;
}

.filterSecond {
  max-width: 217px;
  width: 100%;
  padding: 24px 16px 24px 16px;
  min-height: 100%;
  border-left: 1px solid #cbd5e0;
  overflow: auto;
  @media screen and (min-width: 768px) {
    width: 217px;
  }
}

.checkBoxItem {
  position: relative;
  display: flex;
  align-items: center;
  input {
    position: absolute;
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    &:hover ~ .checkmark {
      border: 1px solid var(--grey500);
    }
    &:checked ~ .checkmark {
      background-color: var(--green);
      border: none;
      .check_icon {
        display: block;
      }
    }
  }
  .checkmark {
    width: 17px;
    height: 17px;
    border-radius: 5px;
    flex-shrink: 0;
    border: 1px solid var(--grey400);
  }
  .checkLabel {
    margin-left: 8px;
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
    color: var(--grey700);
  }
  .check_icon {
    display: none;
    width: 16px;
    position: absolute;
    pointer-events: none;
  }
}

.sort {
  position: relative;
  margin-left: 8px;
  ul {
    width: 205px;
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 12px 36px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 16px 24px;
    z-index: 2;
    top: calc(100% + 8px);
    li {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      button {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: var(--grey900);
        &:hover {
          color: var(--greendark);
          svg {
            stroke: var(--greendark);
          }
        }
        &.active {
          color: var(--greendark);
          svg {
            stroke: var(--greendark);
          }
        }
        svg {
          width: 15px;
          margin-right: 8px;
          line-height: 0;
          stroke: var(--grey600);
        }
      }
    }
  }
}

.headRight {
  margin-top: 16px;
  @media screen and (min-width: 540px) {
    display: flex;
    margin-left: auto;
    align-items: center;
  }
  @media screen and (min-width: 1024px) {
    margin-top: 0;
  }
}

.search {
  position: relative;
  display: flex;
  align-items: center;
  height: 39px;
  background: var(--grey300);
  border-radius: 8px;
  padding: 0 16px;
  max-width: 250px;

  @media screen and (min-width: 1200px) {
    max-width: 331px;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    border-radius: 8px;
    font-size: 12px;
    line-height: 15px;
    color: var(--grey900);
    &::placeholder {
      color: var(--grey600);
    }
    &:hover {
      @media screen and (min-width: 1024px) {
        background: var(--grey400);
      }
    }
  }
  svg {
    margin-left: auto;
    width: 15px;
    position: relative;
    z-index: 1;
  }
}

.add {
  margin-top: 16px;
  @media screen and (min-width: 540px) {
    margin-top: 0;
    margin-left: 8px;
  }
}

.loading {
  width: 56px;
  height: 56px;
  background-color: var(--green);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: fixed;
  right: 32px;
  z-index: 20;
  bottom: 32px;
}

.more {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  margin-top: 8px;
}

.dateFilterButton {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 39px;
  background: var(--grey300);
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: var(--grey900);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (min-width: 442px) {
    margin-left: 8px;
  }
  &:hover {
    @media screen and (min-width: 1024px) {
      background: var(--grey400);
    }
  }
  svg {
    stroke: var(--grey600);
    margin-right: 8px;
    width: 15px;
  }
}

.dateFilter {
  position: relative;
  margin-top: 16px;
  @media screen and (min-width: 400px) {
    margin-top: 0;
  }
}

.resetDateFilter {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  display: flex;
  svg {
    fill: var(--grey600);
    width: 15px;
    height: 15px;
  }
}

.dateFilterBox {
  display: flex;
  top: calc(100% + 8px);
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 12px 36px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  z-index: 3;
  width: 100vw;
  left: -18px;
  @media screen and (min-width: 440px) {
    left: -244px;
  }
  @media screen and (min-width: 768px) {
    width: 800px;
    left: -318px;
  }
  @media screen and (min-width: 1250px) {
    left: 0;
  }
}
