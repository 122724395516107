.m-loading {
    display: flex;
    justify-content: center;
    padding-top: 3px;
    &--rounded {
      padding-top: 16px;
    }
    &.green {
      div {
        background-color: var(--green);
      }
    }
    div {
      width: 6px;
      height: 6px;
      margin: 3px;
      background: #fff;
      border-radius: 50%;
      animation: 0.9s bounce infinite alternate;
  
      &:nth-child(2) {
        animation-delay: 0.3s;
      }
  
      &:nth-child(3) {
        animation-delay: 0.6s;
      }
    }
  }
  
  @keyframes bounce {
    to {
      opacity: 0.3;
      transform: translate3d(0, -1rem, 0);
    }
  }