.main {
  padding: 49px 0 80px 0;
}

.back_link {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  color: var(--grey600);
  width: auto;
  margin-bottom: 16px;
  &:hover {
      color: var(--grey700);
      svg {
          stroke: var(--grey700)
      }
  }
 
  svg {
      width: 15px;
      margin-right: 8px;
      stroke: var(--grey600)
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.title {
  font-size: 38px;
  font-weight: 800;
  line-height: 48px;
  color: var(--grey900);
}

.pen_icon {
  width: 15px;
}

.list_item {
  &:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--grey300);
    margin-bottom: 24px;
  }
}

.list_text {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
  color: var(--grey900);
}

.bottom {
  margin-top: 64px;
}

.bottomBlock {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}

.bottomTitle {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  color: var(--blue);
  border-bottom: 2px solid var(--grey300);
  padding-bottom: 24px;
  margin-bottom: 40px;
}


.loading {
  width: 56px;
  height: 56px;
  background-color: var(--green);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: fixed;
  right: 32px;
  z-index: 20;
  bottom: 32px;
}
