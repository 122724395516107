.main {
    padding: 0 12px;
    margin-bottom: 32px;
    @media screen and (min-width: 1024px) {
       width: 50%;
    }
    input {
        border: 1px solid var(--grey400);
        border-radius: 8px;
        width: fit-content;
        padding: 12px 16px;
        text-align: center; 
        font-size: 12px;
        line-height: 15px;
        color: var(--grey900);
        &:disabled {
            background-color: var(--grey200);
            cursor: not-allowed;
        }
        &:hover {
            border: 1px solid var(--grey500);
        }
    }
}

.wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;
}

.label {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    color: #000000;
    margin-bottom: 6px;
}


.arrow_icon {
    width: 15px;
  }
  
.minus_icon {
 width: 16px;
}

.checkboxs {
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 40px;
    .checkboxsItem {
        position: relative;
        display: flex;
        align-items: center;
        input {
            position: absolute;
            cursor: pointer;
            display: block;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1;
            &:hover  ~ .checkmark{
                border: 1px solid var(--grey500);
            }
            &:checked ~ .checkmark {
                background-color: var(--green);
                border: none;
                .check_icon {
                  display: block;

                }
              }
        }
        .checkmark {
            width: 17px;
            height: 17px;
            border-radius: 5px;
            flex-shrink: 0;
            border: 1px solid var(--grey400);
        }
        .checkLabel {
            margin-left: 8px;
            font-weight: 700;
            font-size: 10px;
            line-height: 13px;
            color: var(--grey700);
        }
        .check_icon {
            display: none;
            width: 17px;
            position: absolute;
            pointer-events: none;
        }          
    }
}