.main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.item {
    margin-right: 8px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    height: 38px;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    color: var(--grey900);
    background: var(--grey300);
    border-radius: 32px;
    margin-bottom: 8px;
    &:hover {
        background: var(--grey400);
    }
}