.main {
    background-color: #fff;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    height: 100%;
    @media screen and (min-width: 1024px) {
        transition: all 0.2s ease-in-out;
    }
    &:hover {
        @media screen and (min-width: 1024px) {
            box-shadow: 0px 5px 34px rgba(0, 0, 0, 0.14);
        }
    }
}

.picture {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
    position: relative;
    background: var(--grey300);
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    svg {
        width: 40px;
    }
}

.infos {
    padding: 16px;
}

.name {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: var(--grey900);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.date {
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--grey700);
}

.active,
.desactive,
.waiting {
    position: absolute;
    top: 8px;
    left: 8px;
    height: 23px;
    padding: 0 8px;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    border-radius: 6px;
    z-index: 1;
    span {
        width: 5px;
        height: 5px;
        border-radius: 5px;
        margin-right: 4px;
    }
}

.active {
    color: #28910d;
    background: #ddffd5;
    span {
        background-color: #28910d;
    }
}

.desactive {
    background: #ffdedc;
    color: #b53e35;
    span {
        background-color: #b53e35;
    }
}

.waiting {
    background-color: #f5e0d1;
    color: #a9571b;
    span {
        background-color: #a9571b;
    }
}

.createdAt {
    margin-top: 20px;
    font-size: 10px;
    font-weight: 400;
    line-height: 11px;
    color: var(--grey600);
}
