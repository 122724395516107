.list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
    .item {
        
        width: 100%;
        height: 110px;
        margin-bottom: 24px;
        padding: 0 12px;
        @media screen and (min-width: 500px) {
            width: 50%;
        }
        @media screen and (min-width: 1024px) {
            width: 25%;
        }
    }  
}

.subtitle_icon {
    width: 20px;
}
  

.subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 48px;
    margin-bottom: 32px;
    p {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: var(--grey900);
    }
}