.list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -16px;
}

.item {
    margin-bottom: 24px;
    padding: 0 16px;
    @media screen and (min-width: 1024px) {
        width: 50%;
    }
}

.add {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: var(--bluelight);
    &:hover {
        color: var(--bluedark);
    }
}

.text {
    margin-top: -16px;
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    color: var(--grey700);
}
