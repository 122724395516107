.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    p {
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        color: var(--grey900);
    }
    .buttons {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
        label {
          font-size: 12px;
          font-weight: 700;
          line-height: 15px;
          color: var(--grey600);
        }
    }
}