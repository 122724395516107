.subtitle_icon {
    width: 20px;
}
  

.subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 48px;
    margin-bottom: 32px;
    p {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: var(--grey900);
    }
    svg {
        width: 20px;
        stroke:var(--grey600)
    }
}

.item {
    border-bottom: 1px solid var(--grey300);
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    &:first-child {
        padding-top: 0;
    }
}
.label {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    color: var(--grey900);
}
.text {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: var(--grey900);
    text-align: right;
}