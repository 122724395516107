.main {
    position: relative;
    height: 110px;
    max-width: 177px;
    img {
        border-radius: 8px;
        position: absolute;
        width: 100%;
        height: 100%; 
        object-fit: cover;
    }
}

.play {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(26, 32, 44, 0.6);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; 
    &:hover {
       background-color: rgba(26, 32, 44, 0.8);
    }
    svg {
        width: 20px;
        fill: var(--green)
    }
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}



.popupBox { 
    position: relative;

   
    overflow: hidden;
    width: 100%;
    max-width: 1020px;
    max-height: 630px;
    height: 100%;
    iframe {
        left:0; 
        top:0; 
        height:100%;
        width:100%;
        position:absolute;
    }
}
    
  