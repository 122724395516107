.subtitle_icon {
    width: 20px;
}
  

.subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 48px;
    margin-bottom: 32px;
    p {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: var(--grey900);
    }
}

.list {
    display: flex;
    flex-wrap: wrap;
}

.item {
    margin-right: 8px;
    margin-bottom: 8px;
}

.tag {
    display: inline-flex;
    align-items: center;
    padding: 12px 16px;
    background: var(--grey300);
    border-radius: 32px;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    color: var(--grey900);
    &:hover {
        background: var(--grey400);
    }
    svg {
        margin-right: 10px;
        width: 15px;
        stroke: var(--grey900)
    }
}