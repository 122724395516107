.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 2px solid var(--grey300);
    margin-bottom: 48px;
}

.title {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    color: var(--blue);
}

.subtitle_icon {
    width: 20px;
}

.subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 48px;
    margin-bottom: 32px;
    p {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: var(--grey900);
    }
}

.active {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 8px;
    border-radius: 6px;
    background-color: #ffdedc;
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    color: #b53e35;
    span {
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 24px;
        margin-right: 4px;
        background-color: #b53e35;
    }
    &.green {
        background-color: #ddffd4;
        color: #28900d;
        span {
            background-color: #28900d;
        }
    }
}

.list {
    border-bottom: 1px solid var(--grey300);
    padding-bottom: 24px;
    li {
        &:not(:last-child) {
            padding-bottom: 24px;
            border-bottom: 1px solid var(--grey300);
            margin-bottom: 24px;
        }
        .text {
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            color: var(--grey900);
            text-align: right;
        }
    }
}

.hours {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: var(--grey900);
    .arrow,
    .minus {
        height: 16px;
    }

    .arrow {
        margin: 0 10.5px;
    }
    .minus {
        margin: 0 16px;
    }
}

.link {
    color: var(--blue);
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    text-underline-offset: 2px;
}
