.subtitle_icon {
    width: 20px;
}
  

.subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 48px;
    margin-bottom: 32px;
    p {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        color: var(--grey900);
    }
}

.list {
    border-bottom: 1px solid var(--grey300);
    padding-bottom: 24px;
 
}
.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child) {
        padding-bottom: 24px;
        border-bottom: 1px solid var(--grey300);
        margin-bottom: 24px;
    }
}
.label {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: var(--grey900);
}
.text {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: var(--grey900);
    margin-left: 16px;
}