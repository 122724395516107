.container {
  max-width: 1248px;
  margin: 0 auto;
  padding: 0 12px;
  position: relative;
  @media screen and (min-width: 500px) {
    padding: 0 24px;
  }
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: calc(100% / 12 * 3) 1fr;
  }
}

.react-datepicker-wrapper {
  border-radius: 8px;
  background-color: #fff;
  input {
    width: 100%;
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    color: var(--grey900);
    background-color: #fff;
    border: 1px solid var(--grey400);
    padding: 12px 38px 12px 16px;
    border-radius: 8px;
    text-align: left;
    &:disabled {
      background-color: var(--grey200);
      cursor: not-allowed;
    }
    &::placeholder {
      color: var(--grey600);
    }
  }
}

.react-datepicker {
  &__triangle {
    display: none;
  }
  &__month-container:last-child .react-datepicker__header {
    margin-top: 16px;
    @media screen and (min-width: 800px) {
      margin-top: 0;
    }
  }
}

.react-datepicker__day-names {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  @media screen and (min-width: 800px) {
    display: block;
  }
}

.react-datepicker__day-name {
  display: inline-flex;
  width: 35px;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  color: var(--grey600);
  @media screen and (min-width: 335px) {
    width: 40px;
  }
  @media screen and (min-width: 375px) {
    width: 45px;
  }
  @media screen and (min-width: 415px) {
    width: 52px;
  }
}
.react-datepicker__week {
  width: fit-content;
  display: flex;
  margin: 0 auto;
  @media screen and (min-width: 800px) {
    margin: 0;
  }
  .react-datepicker__day {
    display: inline-flex;
    cursor: pointer;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    border-radius: 6px;
    color: var(--grey900);
    @media screen and (min-width: 335px) {
      width: 40px;
      height: 40px;
    }
    @media screen and (min-width: 375px) {
      width: 45px;
      height: 45px;
    }
    @media screen and (min-width: 415px) {
      width: 52px;
      height: 52px;
    }
    &.react-datepicker__day--outside-month {
      color: var(--grey500);
      background-color: transparent !important;
      pointer-events: none;
    }
    &.react-datepicker__day--selected,
    &.react-datepicker__day--in-range {
      background-color: var(--green);
    }
    &.react-datepicker__day--in-selecting-range {
      background-color: var(--grey200);
    }
    &.react-datepicker__day--in-range,
    &.react-datepicker__day--in-selecting-range {
      border-radius: 0;
    }
    &.react-datepicker__day--range-start {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    &.react-datepicker__day--range-end {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}

.event-datepicker {
  .react-datepicker {
    max-width: inherit;
  }
  .react-datepicker .react-datepicker__week {
    width: auto;
  }
}
