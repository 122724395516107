.main {
    ul {
        display: flex;
        flex-wrap: wrap;
        
        li {
           width: 100%;
           margin-bottom: 16px;
           iframe {
            width: 100%;
            height: 84px;
           }
        }
    }
    
}

.subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 48px;
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--grey900);
    .subtitle_icon {
        width: 20px;
    }
}

.add {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: var(--bluelight);
    &:hover {
        color: var(--bluedark);
    }
    
}

.delete {
    margin-top: 8px;
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
    color: var(--grey600);
    &:hover {
        color: var(--grey700);
    }
}


.popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    .box {
        position: relative;
        width: 515px;
        padding: 40px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 12px 36px rgba(0, 0, 0, 0.08);
    }
}

.popupTitle {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--grey900);
    padding-bottom: 24px;
    border-bottom: 2px solid var(--grey300);
    margin-bottom: 40px;
    svg {
        width: 18px;
        margin-right: 9px;
    }
}

.popupText {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: var(--grey700);
}

.popupButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
}
