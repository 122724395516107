.main {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.content_wrapper {
  padding: 48px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 1024px) {
    padding: 0;
    width: 50%;
  }
}

.form_container {
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
  @media screen and (min-width: 1024px) {
    width: 100%;
    max-width: 384px;
  }
}

.illustration {
  display: none;
  width: 50%;
  height: 100vh;
  object-fit: cover;
  @media screen and (min-width: 1024px) {
    display: block;
  }
}

.logo {
  position: absolute;
}

.link {
  display: block;
  width: fit-content;
  margin: 32px auto 0 auto;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
  color: var(--blue);
}

.eye_icon {
  width: 15px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.input_wrapper {
  width: 100%;
  margin-bottom: 32px;
}

.password_wrapper {
  position: relative;
}

.input_flex {
  @media screen and (min-width: 425px) {
    display: flex;
    align-items: center;
    gap: 32px;
  }
}
