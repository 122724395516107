.logo {
  position: absolute;
  left: 24px;
  top: 48px;
  @media screen and (min-width: 768px) {
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
  @media screen and (min-width: 1024px) {
    width: 100%;
    left: 50%;
    max-width: 384px;
    transform: translateX(-50%);
  }
}
.link {
  display: block;
  width: fit-content;
  margin: 32px auto 0 auto;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
  color: var(--blue);
}

.item {
  margin-bottom: 32px;
  ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 4px;
  }
  li {
    margin: 0;
    svg {
      width: 15px;
    }
    span {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
    }
    &.invalid span {
      color: var(--zzred);
    }
  }
}
