.subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 48px;
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--grey900);
    .subtitle_icon {
        width: 20px;
    }
}




.hoursWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
}